<template>
  <div class="home">

    <div class="page-container">

      <span class="title-header">
        jeux disponible
        <span class="title-count">(pour le moment les clés sont envoyé manuellement attendez la fin du stream)</span>
      </span>

      <div class="message-info" v-if="!requests.length">
        <i class="fas fa-info-circle"></i>Vous n'avez aucune demande jeu en attente
      </div>


      <div class="requests-list">

        <div class="request" v-for="request in requests" :key="request.id">

          <template v-if="!request.game">

          </template>

          <template v-else class="request">

            <div class="img" :style="{
              'background-image': `url(${request.game.imgUrl})`
            }"></div>

            <div class="title-name">
              {{request.game.name}}
            </div>

            <div class="buyat">
              Acheter <b>{{ moment(request.buyAt.seconds * 1000).calendar() }}</b>
            </div>

            <div class="price">
              Contre <b>{{ new Intl.NumberFormat().format(request.price) }}</b> Asticots
            </div>

            <div class="status">
              <span v-if="request.valid">Validé</span>
              <span v-else>Envois manuel</span>
            </div>
            
          </template>

  

        </div>        
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

moment.locale('fr');  

export default {
  name: 'Request',

  firestore() {
    return {
      rewards: db.collection('rewards').orderBy('buyAt', 'desc'),
      users: db.collection('users').where('userId', '==', this.user.id),
      games: db.collection('games'),
    }
  },

  data () {
    return {
      rewards: [],
      games: [],
      users: [],

      moment
    }
  },

  computed: {
    ...mapState(['user']),
    requests () {
      return this.rewards.filter(x => x.userId === this.userData.userId).map(x => ({
        ...x,
        game: this.games.find(y => y.id === x.gameId)
      }))
    },
    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    }
  }

}

</script>

<style lang="scss" scoped>

.message-info {
  width: 100%;
  text-align: center;

  margin-top: 24px;

  background: #202225;
  padding: 14px 18px;
  border-radius: 5px;

  svg {
    margin-right: 8px;
  }
}

@media screen and (max-width: 900px) {
  .title-header {
    align-items: flex-start !important;
    text-align: left !important;
    flex-direction: column !important;

    .title-count {
      margin-top: 12px;
    }
  }

  .request {
    flex-direction: column !important;
    height: unset !important;

    div {
      margin: 0 !important;
      text-align: left !important;
      width: 100% !important;
      height: 24px;
    }

    .title-name {
      margin-top: 12px !important;
    }

  }
}

.requests-list {
  margin-top: 42px;
}

.request {
  height: 84px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;

  background: #171717;

  border-radius: 5px;

  overflow: hidden;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .title-name {
    margin-right: 24px;

    width: 15%;
    color: white;
  }

  .buyat {
    font-weight: 300;

    width: 25%;

    b {
      color: white;
      text-transform: lowercase;
      margin-left: 6px;
    }
  }

  .price {
    text-align: right;
    width: 20%;

    b {
      color: white;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .status {
    width: 30%;
    padding-right: 32px;
    text-align: right;
  }

  .img {
    width: 84px;
    height: 84px;

    background-position: center;
    background-size: cover;

    border-right: #202225 1px solid;
    margin-right: 18px;
  }

}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
}

@media screen and (max-width: 1500px) {
  .page-container {
      max-width: 1225px;
  }
}

@media screen and (max-width: 1225px) {
  .page-container {
      max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .page-container {
      max-width: 610px;
  }
}


@media screen and (max-width: 610px) {
}

</style>

